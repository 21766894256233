import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Steps = class Steps extends Vue {
};
__decorate([
    Prop()
], Steps.prototype, "stepCount", void 0);
__decorate([
    Prop()
], Steps.prototype, "currentStep", void 0);
Steps = __decorate([
    Component
], Steps);
export default Steps;
