var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.subscribed),expression:"!subscribed"}]},[_c('p',{staticClass:"mb-4"},[_vm._v(" Just one last step, let's setup your monthly subscription. Your card will be charged once your free membership period has lapsed - unless you cancel. Your card details are stored on Stripe and we have no access to them. ")]),(_vm.plans != null)?_c('p',{staticClass:"mb-2 text-sm"},[_c('strong',[_vm._v(_vm._s(_vm.plans[0].name))]),_vm._v(" at "),_c('strong',[_vm._v("$"+_vm._s((parseInt(_vm.plans[0].amount) / 100).toFixed(2))+"/mo")]),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.plans[0].has_previous_plan && _vm.plans[0].trial_days > 0),expression:"!plans[0].has_previous_plan && plans[0].trial_days > 0"}]},[_vm._v("("+_vm._s(_vm.plans[0].trial_days)+" Day Free-Trial)")])]):_vm._e(),_c('form',{directives:[{name:"show",rawName:"v-show",value:(
                (!_vm.loadingPlans || !_vm.loadingPaymentMethods || !_vm.loadingSetupIntent) &&
                _vm.payment_methods.length > 0 &&
                _vm.plans != null &&
                !_vm.addNewMethod
            ),expression:"\n                (!loadingPlans || !loadingPaymentMethods || !loadingSetupIntent) &&\n                payment_methods.length > 0 &&\n                plans != null &&\n                !addNewMethod\n            "}],staticClass:"pt-4",attrs:{"data-component":"subscription-details"}},[_c('select-input',{staticClass:"mb-4",attrs:{"id":"country","value":_vm.payment_method,"label":'Payment Method',"placeholder":'Select payment method',"error":_vm.errors.payment_method,"options":_vm.payment_method_options},on:{"update:value":function($event){_vm.payment_method=$event}}}),_c('span',{staticClass:"text-red-500 cursor-pointer hover:underline",on:{"click":function($event){;(_vm.addNewMethod = true), _vm.createSetupIntent()}}},[_vm._v("Add New Payment Method »")]),(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"text-red-500 font-bold text-sm mt-2"},[_c('p',[_vm._v(_vm._s(_vm.errors[0]))])]):_vm._e(),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.updating),expression:"!updating"}],staticClass:"mt-6 w-full",attrs:{"label":_vm.updating ? _vm.$i18n.t('base.loading') : _vm.$i18n.t('form.button.next')},on:{"click":function($event){return _vm.createSubscription()}}}),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.updating),expression:"updating"}],staticClass:"mt-6 w-full",attrs:{"label":_vm.updating ? _vm.$i18n.t('base.loading') : _vm.$i18n.t('form.button.next'),"disabled":true}})],1),_c('form',{directives:[{name:"show",rawName:"v-show",value:(
                (!_vm.loadingPlans || !_vm.loadingPaymentMethods || !_vm.loadingSetupIntent) &&
                _vm.setup_intent != null &&
                _vm.plans != null &&
                _vm.addNewMethod
            ),expression:"\n                (!loadingPlans || !loadingPaymentMethods || !loadingSetupIntent) &&\n                setup_intent != null &&\n                plans != null &&\n                addNewMethod\n            "}],staticClass:"pt-4",attrs:{"data-component":"subscription-details"}},[(_vm.payment_methods.length > 0)?_c('span',{staticClass:"text-red-500 cursor-pointer hover:underline mb-4",on:{"click":function($event){_vm.addNewMethod = false}}},[_vm._v("« Select Existing Payment Method")]):_vm._e(),_c('div',{ref:"card",staticClass:"border p-2 mt-4",class:{
                    'border-red-500': _vm.errors && _vm.errors.length > 0,
                }}),(_vm.errors && _vm.errors.length > 0)?_c('div',{staticClass:"text-red-500 font-bold text-sm mt-2"},[_c('p',[_vm._v(_vm._s(_vm.errors[0]))])]):_vm._e(),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.updating),expression:"!updating"}],staticClass:"mt-6 w-full",attrs:{"label":_vm.updating ? _vm.$i18n.t('base.loading') : _vm.$i18n.t('form.button.next')},on:{"click":function($event){return _vm.createPaymentMethod()}}}),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.updating),expression:"updating"}],staticClass:"mt-6 w-full",attrs:{"label":_vm.updating ? _vm.$i18n.t('base.loading') : _vm.$i18n.t('form.button.next'),"disabled":true}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingPlans && _vm.loadingPaymentMethods && _vm.loadingSetupIntent),expression:"loadingPlans && loadingPaymentMethods && loadingSetupIntent"}],staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"text-base text-ag-brown-grey mr-2",attrs:{"icon":['fal', 'spinner-third'],"spin":""}}),_c('p',{staticClass:"text-sm text-ag-brown-grey"},[_vm._v(_vm._s(_vm.$i18n.t('base.loading')))])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subscribed),expression:"subscribed"}],staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"text-5xl text-green-500 mb-2",attrs:{"icon":['fal', 'check-circle']}}),_c('p',[_vm._v("Success! Your subscription has been successful.")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }