import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Steps from '@/components/Progress/Steps.vue';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import Btn from '@/components/Button/Btn.vue';
import CreateSubscription from '@/views/Subscribe/Forms/CreateSubscription.vue';
let Subscribe = class Subscribe extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
    }
    async mounted() {
        this.$store.dispatch('user/getUserProfile');
        const profile = this.$store.getters['user/getProfile'];
        if (profile && profile.subscribed) {
            this.$router.push('my-village');
        }
        else {
            this.loading = false;
        }
    }
    submitSubscriptionDetails() {
        this.$store.dispatch('user/getUserProfile');
        this.$router.push('my-village');
    }
    async logout() {
        const response = await this.$store.dispatch('auth/logout');
        if (response == true) {
            this.$router.push({ name: 'login' });
        }
    }
};
Subscribe = __decorate([
    Component({
        components: {
            Steps,
            FormWrap,
            PublicPageTitle,
            Btn,
            CreateSubscription,
        },
    })
], Subscribe);
export default Subscribe;
