import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Steps from '@/components/Progress/Steps.vue';
import { Getter } from 'vuex-class';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import UserBio from '@/views/Onboarding/Forms/UserBio.vue';
import UserInterests from '@/views/Onboarding/Forms/UserInterests.vue';
import ContactDetails from '@/views/Onboarding/Forms/ContactDetails.vue';
import ManifestoAgreement from '@/views/Onboarding/Forms/ManifestoAgreement.vue';
import CreateSubscription from '@/views/Subscribe/Forms/CreateSubscription.vue';
let Onboarding = class Onboarding extends Vue {
    constructor() {
        super(...arguments);
        this.stepCount = 3;
        this.step = 1;
        this.form = {
            name: '',
            email: '',
            description: '',
            country_code: '',
            city: '',
            phone: '',
            address: '',
            company_name: '',
            company_phone: '',
            company_address: '',
            labels: [],
        };
    }
    submitBio() {
        this.step = 2;
    }
    // submitContactDetails() {
    //     this.step = 3
    // }
    submitManifestoAgreement() {
        this.$store.dispatch('user/setOnboarding', true);
        this.$router.push('my-village');
    }
};
__decorate([
    Getter('onboarding', { namespace: 'user' })
], Onboarding.prototype, "onboarding", void 0);
Onboarding = __decorate([
    Component({
        components: {
            Steps,
            FormWrap,
            PublicPageTitle,
            UserBio,
            UserInterests,
            ContactDetails,
            CreateSubscription,
            ManifestoAgreement
        },
    })
], Onboarding);
export default Onboarding;
