import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SubscriptionService from '@/services/subscriptions.ts';
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import { loadStripe } from '@stripe/stripe-js';
let CreateSubscription = class CreateSubscription extends Vue {
    constructor() {
        super(...arguments);
        this.errors = [];
        this.loadingPlans = true;
        this.loadingPaymentMethods = true;
        this.loadingSetupIntent = true;
        this.addNewMethod = false;
        this.updating = false;
        this.subscribed = false;
        this.stripe_key = process.env.VUE_APP_STRIPE_KEY;
        this.stripe = undefined;
        this.card = undefined;
        this.plans = null;
        this.payment_methods = [];
        this.car_holder_name = '';
        this.setup_intent = [];
        this.product_id = null;
        this.price_id = null;
        this.payment_method = null;
        this.payment_method_options = [];
    }
    async mounted() {
        this.car_holder_name = this.$store.getters['user/getProfile'].name;
        /* @ts-ignore */
        this.stripe = await loadStripe(this.stripe_key);
        this.card = this.stripe.elements().create('card');
        this.card.mount(this.$refs.card);
        this.getPlans();
        this.getPaymentMethods();
    }
    async getPlans() {
        try {
            let plans = await SubscriptionService.getPlans();
            this.plans = plans.data.plans;
            this.product_id = this.plans[0].product_id;
            this.price_id = this.plans[0].price_id;
            this.loadingPlans = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
            this.loadingPlans = false;
        }
    }
    async getPaymentMethods() {
        try {
            let response = await SubscriptionService.getPaymentMethods();
            this.payment_methods = response.data.payment_methods;
            if (this.payment_methods.length == 0) {
                await this.createSetupIntent();
                this.addNewMethod = true;
            }
            else {
                this.payment_method_options = this.payment_methods?.map((method) => {
                    return {
                        name: `${method.card.brand.toUpperCase()} ending ${method.card.last4}`,
                        value: method.id,
                        selected: false,
                    };
                });
                this.payment_method = this.payment_method_options[0].value;
            }
            this.loadingPaymentMethods = false;
        }
        catch (e) {
            await this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
            this.loadingPaymentMethods = false;
        }
    }
    async createSetupIntent() {
        try {
            let response = await SubscriptionService.createSetupIntent();
            this.setup_intent = response.data.setup_intent;
            this.loadingSetupIntent = false;
        }
        catch (e) {
            await this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
            this.loadingSetupIntent = false;
        }
    }
    async createPaymentMethod() {
        try {
            this.$store.dispatch('notification/hideNotification');
            this.errors = [];
            this.updating = true;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(this.setup_intent.val.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.car_holder_name,
                    },
                },
            });
            if (error) {
                // Display "error.message" to the user...
                this.$store.dispatch('notification/showError', error.message);
                this.updating = false;
                this.errors.push(error.message);
            }
            else {
                // The card has been verified successfully...
                this.payment_method = setupIntent.payment_method;
                this.createSubscription();
            }
        }
        catch (e) {
            this.updating = false;
            this.$store.dispatch('notification/showError', 'Error');
            this.errors = e;
        }
    }
    async createSubscription() {
        try {
            this.updating = true;
            let subscription = await SubscriptionService.createSubscription({
                type: 'base',
                product_id: this.product_id,
                price_id: this.price_id,
                payment_method: this.payment_method,
                trial_days: parseInt(this.plans[0].trial_days),
                quantity: 1,
                metadata: {
                    model_type: 'App\\Models\\User',
                    model_id: this.profile.id,
                },
            });
            this.subscribed = true;
            this.updating = false;
            await this.$store.dispatch('user/getUserProfile');
            setTimeout(() => {
                this.formSubmitted();
            }, 2000);
        }
        catch (e) {
            this.updating = false;
            this.$store.dispatch('notification/showError', e.response.data.message);
            this.errors = e;
        }
    }
    formSubmitted() {
        this.$emit('formSubmitted');
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], CreateSubscription.prototype, "profile", void 0);
CreateSubscription = __decorate([
    Component({
        components: {
            Btn,
            TextInput,
            SelectInput,
        },
    })
], CreateSubscription);
export default CreateSubscription;
