import { __decorate } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// services
import UserService from '@/services/user.ts';
// components
import Btn from '@/components/Button/Btn.vue';
import LabelSelect from '@/components/FormInputs/LabelSelect/LabelSelect.vue';
let UserInterests = class UserInterests extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            description: '',
            country_code: '',
            city: '',
            phone: '',
            address: '',
            company_name: '',
            company_phone: '',
            company_address: '',
            onboarding: false,
            labels: [],
        };
        this.errors = {
            labels: [],
        };
        this.labelsCount = this.form.labels.length;
    }
    // Watch for changes in form and update errors per input
    async onLabelsChange() {
        this.labelsCount = this.form.labels.length;
        this.errors.labels = [];
    }
    async mounted() {
        if (this.profile) {
            this.form = await UserService.setUserForm();
        }
    }
    // Submit form here
    async submit() {
        try {
            await this.$store.dispatch('user/updateUser', this.form);
            this.$emit('formSubmitted', this.form);
        }
        catch (e) {
            this.errors = e;
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UserInterests.prototype, "profile", void 0);
__decorate([
    Watch('form.labels')
], UserInterests.prototype, "onLabelsChange", null);
UserInterests = __decorate([
    Component({
        components: {
            Btn,
            LabelSelect,
        },
    })
], UserInterests);
export default UserInterests;
