import { __decorate } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// services
import UserService from '@/services/user.ts';
// components
import Btn from '@/components/Button/Btn.vue';
import TextareaInput from '@/components/FormInputs/TextAreaInput/TextAreaInput.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
// forms
import UpdateAvatar from '@/views/Profile/Forms/UpdateAvatar.vue';
let UserBio = class UserBio extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            description: '',
            country_code: '',
            city: '',
            phone: '',
            address: '',
            company_name: '',
            company_phone: '',
            company_address: '',
            onboarding: false,
            labels: [],
        };
        this.errors = {
            description: [],
            country: [],
            city: [],
        };
        this.loading = true;
        this.countryOptions = [];
    }
    // Watch for changes in form and update errors per input
    async onDescriptionChange() {
        this.errors.description = [];
    }
    async onCountryChange() {
        this.errors.country = [];
    }
    async onSelectedCountryChange() {
        this.errors.country = [];
    }
    async onCityChange() {
        this.errors.city = [];
    }
    async mounted() {
        if (this.profile) {
            this.form = await UserService.setUserForm();
            this.loading = false;
        }
        if (this.countries && this.countries.length <= 0) {
            await this.$store.dispatch('app/getCountries');
        }
        // setup SelectOptions
        this.countryOptions = this.countries?.map((country) => {
            return {
                name: country.name,
                value: country.code,
                selected: false,
            };
        });
    }
    // Submit form here
    async submit() {
        try {
            this.form.labels = await UserService.setUserLabelsArray(this.profile.labels);
            const resp = await this.$store.dispatch('user/updateUser', this.form);
            this.$emit('formSubmitted', this.form);
        }
        catch (e) {
            this.errors = e;
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UserBio.prototype, "profile", void 0);
__decorate([
    Getter('countries', { namespace: 'app' })
], UserBio.prototype, "countries", void 0);
__decorate([
    Watch('form.description')
], UserBio.prototype, "onDescriptionChange", null);
__decorate([
    Watch('form.country')
], UserBio.prototype, "onCountryChange", null);
__decorate([
    Watch('form.country_code')
], UserBio.prototype, "onSelectedCountryChange", null);
__decorate([
    Watch('form.city')
], UserBio.prototype, "onCityChange", null);
UserBio = __decorate([
    Component({
        components: {
            Btn,
            TextareaInput,
            TextInput,
            SelectInput,
            UpdateAvatar,
        },
    })
], UserBio);
export default UserBio;
