import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// services
import UserService from '@/services/user.ts';
// components
import Btn from '@/components/Button/Btn.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
let ContactDetails = class ContactDetails extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            description: '',
            country_code: '',
            city: '',
            phone: '',
            address: '',
            company_name: '',
            company_phone: '',
            company_address: '',
            onboarding: true,
            labels: []
        };
        this.errors = {
            phone: [],
            address: [],
            company_name: [],
            company_phone: [],
            company_address: [],
        };
    }
    async mounted() {
        if (this.profile) {
            this.form = await UserService.setUserForm();
        }
    }
    async submit() {
        try {
            this.form.labels = await UserService.setUserLabelsArray(this.profile.labels);
            await this.$store.dispatch('user/updateUser', this.form);
            this.$emit('formSubmitted', this.form);
        }
        catch (e) {
            this.errors = e;
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], ContactDetails.prototype, "profile", void 0);
ContactDetails = __decorate([
    Component({
        components: {
            Btn,
            TextInput,
        },
    })
], ContactDetails);
export default ContactDetails;
